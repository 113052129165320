import { FrontPageSliderCard, Package } from "../types/types.d";

export { };

export var cards: FrontPageSliderCard[] = [
    // { order: 1, type: 'image', title: '', url: './images/cards/rigFront.jpg' },
    // { order: 2, type: 'image', title: 'Best thing 2', url: './images/cards/27-inch-agency-cart.jpg' },
    { order: 1, type: 'image', title: 'Low cost, bespoke streaming and replay', url: './images/cards/low-cost-bespoke-VTR.jpg' },
    { order: 2, type: 'image', title: 'Remote comms and director presence', url: './images/cards/greenScreen.jpg' },
    { order: 3, type: 'image', title: 'Run & gun and multi cam QTAKE rigs', url: './images/cards/3up-gear-bw.jpg' },
    { order: 4, type: 'image', title: 'Fixed price, mix n\' match solutions', url: './images/cards/signal_flow.jpg' },
    { order: 5, type: 'image', title: 'Over a ton of equipment for rent', url: './images/cards/gear-rental.jpg' },
    { order: 6, type: 'video', title: 'Reel', url: 'CayWeERluRY' },
];


export var packages: Package[] = [
    { headline: 'Run and Gun', tagline: 'Qtake in a case', description: 'incldues transmitters, 2 monitors, trollies and stands' },
    { headline: 'A and Gun', tagline: 'Qtake in a case', description: 'incldues transmitters, 2 monitors, trollies and stands' },
];




// https://www.youtube.com/embed/CayWeERluRY?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;mute=1&amp;loop=1

